<template>
  <div>
    <h2>AI Interview</h2>
    <button @click="startInterview" v-if="!interviewStarted">Start Interview</button>
    <div v-if="interviewStarted">
      <div v-for="(qa, index) in previousQA" :key="index">
        <p><strong>Q: {{ qa.question }}</strong></p>
        <p>A: {{ qa.answer }}</p>
      </div>
      <p v-if="currentQuestion"><strong>Q: {{ currentQuestion }}</strong></p>
      <textarea v-model="userAnswer" :disabled="interviewComplete" rows="4" cols="50"></textarea>
      <button @click="submitAnswer" :disabled="interviewComplete || !userAnswer">Submit Answer</button>
    </div>
    <p v-if="interviewComplete">Interview completed. Thank you for your responses!</p>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'InterviewPage',
  data() {
    return {
      interviewStarted: false,
      previousQA: [],
      currentQuestion: '',
      userAnswer: '',
      interviewComplete: false
    }
  },
  methods: {
    async startInterview() {
      try {
        const token = localStorage.getItem('token')
        const response = await axios.get('/start_interview', {
          headers: { Authorization: `Bearer ${token}` }
        })
        this.currentQuestion = response.data.question
        this.interviewStarted = true
      } catch (error) {
        console.error(error)
      }
    },
    async submitAnswer() {
      try {
        const token = localStorage.getItem('token')
        const response = await axios.post('/continue_interview', {
          previous_qa: this.previousQA,
          user_answer: this.userAnswer
        }, {
          headers: { Authorization: `Bearer ${token}` }
        })
        this.previousQA.push({ question: this.currentQuestion, answer: this.userAnswer })
        this.currentQuestion = response.data.ai_response
        this.userAnswer = ''
        this.interviewComplete = response.data.is_complete
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>