<template>
  <div class="home">
    <h1>Welcome to WM Prototype</h1>
    <div class="actions">
      <router-link to="/register" class="btn">Register</router-link>
      <router-link to="/login" class="btn">Login</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage'
}
</script>

<style scoped>
.home {
  text-align: center;
  padding: 20px;
}
.actions {
  margin-top: 20px;
}
.btn {
  display: inline-block;
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}
</style>